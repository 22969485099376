import React from 'react';
import Section from 'components/Section';
import SkillCard from 'components/SkillCard';
import { Title, Subtitle, CardsList } from 'ui';

const Skills = ({ skills, title, description }) => {
  if (skills.length === 0) {
    return null;
  }

  return (
    <Section
      footerElement={
        <Section.MoreLink to="/competences">
          Voir toutes les compétences
        </Section.MoreLink>
      }
    >
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
      <CardsList variant="articles">
        {skills.map((skill, index) => (
          <SkillCard skill={skill} key={index} variant="homepage" />
        ))}
      </CardsList>
    </Section>
  );
};

export default Skills;
