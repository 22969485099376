import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import Section from 'components/Section';
import heroBackground from 'images/hero-background.svg';
import { Player } from '@lottiefiles/react-lottie-player';
import lottieAnimation from 'images/logo-animation-2.json';

const Wrapper = styled(Section).attrs({ variant: 'primary' })`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-image: url(${heroBackground});
`;

const HomeHero = () => (
  <Wrapper>
    <Stack size="full" alignX="center">
      <Player
        autoplay
        keepLastFrame
        src={lottieAnimation}
        style={{ height: '300px', width: '300px' }}
      />
    </Stack>
  </Wrapper>
);

export default HomeHero;
