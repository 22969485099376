import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Slider from 'react-slick';
import Section from 'components/Section';
import 'slick-carousel/slick/slick.css';
import ArticleCard from './ArticleCard';
import { Title } from 'ui';
import { ButtonReset } from '@tymate/margaret';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Arrow = styled(ButtonReset)`
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  font-size: ${({ theme }) => theme.spacing(2)};
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  color: #ffffff;
  background-color: ${({ theme }) => theme.primary};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  transition: background-color 150ms ease, color 150ms ease;

  ${({ variant, theme }) =>
    variant === 'previous' &&
    `
      left: ${theme.spacing()};
    `}

  ${({ variant, theme }) =>
    variant === 'next' &&
    `
      right: ${theme.spacing()};
    `}

  &.slick-disabled {
    color: ${({ theme }) => theme.textLighter};
    background-color: #ffffff;
  }

  svg {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Prev = props => (
  <Arrow {...props} variant="previous" aria-label="Previous articles">
    <MdKeyboardArrowLeft />
  </Arrow>
);

const Next = props => (
  <Arrow {...props} variant="next" aria-label="Next articles">
    <MdKeyboardArrowRight />
  </Arrow>
);

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4.5,
  slidesToScroll: 4,
  nextArrow: <Next />,
  prevArrow: <Prev />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.15,
        slidesToScroll: 1,
      },
    },
  ],
};

const CarouselStyles = createGlobalStyle`
  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    padding: ${({ theme }) => theme.spacing()};
    float: none;
    height: auto;

    > div {
      height: 100%;
    }
  }

  .slick-arrow {

  }
`;

const ArticlesCarousel = ({ articles, title }) => (
  <Section
    footerElement={
      <>
        <CarouselStyles />
        <Slider {...settings}>
          {articles.map(({ node }, index) => (
            <ArticleCard article={node} key={index} />
          ))}
        </Slider>
        <Section.MoreLink to="/blog">Voir tous les articles</Section.MoreLink>
      </>
    }
  >
    <Title>{title}</Title>
  </Section>
);

export default ArticlesCarousel;
