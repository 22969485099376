import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import Section from 'components/Section';
import { Title, CardsList, fontStyles } from 'ui';

const ValueTitle = styled.h3`
  ${fontStyles.h3}
  margin: 0;
  font-weight: 700;
  padding-top: ${({ theme }) => theme.spacing()};
`;

const ValueDescription = styled.div`
  ${fontStyles.bodyLarge}
`;

const Values = ({ values, title }) => {
  if (values.length === 0) {
    return null;
  }

  return (
    <Section variant="primary">
      <Title>{title}</Title>

      <CardsList variant="auto">
        {values.map(({ title, description }, index) => (
          <Stack direction="column" gutterSize={1} key={index}>
            <ValueTitle>{title}</ValueTitle>
            <ValueDescription>{description}</ValueDescription>
          </Stack>
        ))}
      </CardsList>
    </Section>
  );
};

export default Values;
