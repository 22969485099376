import React from 'react';
import styled from 'styled-components';
import { Stack, useMargaret, media } from '@tymate/margaret';
import { Title, Container, fontStyles } from 'ui';
import { SectionBase } from 'components/Section';
import Img from 'gatsby-image';
import Background from 'gatsby-background-image';

const BackgroundInner = styled(Stack).attrs({
  size: 'full',
  alignY: 'stretch',
  alignX: 'center',
})`
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  > ${SectionBase} {
    display: flex;
    align-items: stretch;
    z-index: 2;

    > ${Container} {
      display: flex;
      max-width: 100vw;
    }
  }
`;

const Description = styled.div`
  ${fontStyles.bodyLarge};

  ${media.tablet`
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: #fff;
  `}
`;

const H1 = styled.h1`
  margin: 0;
  text-align: center;
  font-weight: 400;
`;

const SubHeading = styled.div`
  ${fontStyles.h3};

  ${media.tablet`
    color: #fff;
  `}
`;

const Heading = styled.div`
  ${fontStyles.h1Mega};
  font-weight: 600;

  ${media.tablet`
    color: #fff;
  `}
`;

const LawyersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${({ theme }) => theme.spacing(2)};

  ${media.tablet`
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing(2)}
  `};

  ${media.desktop`
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing(5)}
  `};
`;

const Image = styled(Img)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Lawyers = ({ title, subtitle, image, lawyers }) => {
  const { isMobile } = useMargaret();

  return (
    <div>
      {isMobile ? (
        <>
          <SectionBase>
            <Container>
              <Stack alignY="space-between" direction="column">
                <Stack
                  direction="column"
                  alignX="center"
                  gutterSize={0.5}
                  size="full"
                  marginBottom={1}
                >
                  <H1>
                    <SubHeading as="div"> {subtitle}</SubHeading>
                    <Heading as="div">{title}</Heading>
                  </H1>
                </Stack>
                <Image fluid={image?.fluid} alt={image?.alt} />
                <LawyersGrid columns={lawyers.length}>
                  {lawyers.map(({ name, id, description }) => (
                    <Stack direction="column" key={id}>
                      <Title>{name}</Title>
                      <Description>{description}</Description>
                    </Stack>
                  ))}
                </LawyersGrid>
              </Stack>
            </Container>
          </SectionBase>
        </>
      ) : (
        <Background fluid={image?.fluid}>
          <BackgroundInner>
            <SectionBase>
              <Container>
                <Stack alignY="space-between" direction="column" gutterSize={2}>
                  <Stack
                    direction="column"
                    alignX="center"
                    gutterSize={0.5}
                    size="full"
                  >
                    <H1>
                      <SubHeading as="div">{subtitle}</SubHeading>
                      <Heading as="div">{title}</Heading>
                    </H1>
                  </Stack>
                  <LawyersGrid columns={lawyers.length}>
                    {lawyers.map(({ name, id, description }) => (
                      <Stack direction="column" key={id}>
                        <Title variant="light">{name}</Title>
                        <Description>{description}</Description>
                      </Stack>
                    ))}
                  </LawyersGrid>
                </Stack>
              </Container>
            </SectionBase>
          </BackgroundInner>
        </Background>
      )}
    </div>
  );
};

export default Lawyers;
