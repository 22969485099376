import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import Section from 'components/Section';
import { Title, CardsList, fontStyles } from 'ui';
import { useIntersection, useDeepCompareEffect } from 'react-use';
import { useSpring, animated } from 'react-spring';

const ValueWrapper = styled.div`
  ${fontStyles.h1Mega};
  font-weight: 700;
  color: ${({ theme }) => theme.text};
  padding-top: ${({ theme }) => theme.spacing()};
`;

const Label = styled.div`
  ${fontStyles.bodyLarge}
  max-width: 10em;
  color: #ffffff;
`;

const Value = ({ value: targetValue, unit, label, isIntersecting }) => {
  const [value, setValue] = useState(0);
  const props = useSpring({
    value,
    config: { duration: value * 30 >= 3500 ? 3500 : value * 40 },
  });

  useDeepCompareEffect(() => {
    if (isIntersecting) {
      setValue(Number(targetValue));
    }
  }, [{ isIntersecting }]);

  return (
    <ValueWrapper value={value}>
      <animated.span>
        {props.value.to(value => Math.floor(value))}
      </animated.span>{' '}
      {unit}
      <Label>{label}</Label>
    </ValueWrapper>
  );
};

const Stats = ({ stats, title }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const { isIntersecting } = intersection || {};

  if (stats.length === 0) {
    return null;
  }

  return (
    <Section variant="primary">
      <Title>{title}</Title>
      <div ref={intersectionRef} />
      <CardsList variant="auto">
        {stats.map(({ value, label, unit }, index) => (
          <Stack direction="column" gutterSize={0.5} key={index}>
            <Value
              value={value}
              unit={unit}
              label={label}
              isIntersecting={isIntersecting}
            />
          </Stack>
        ))}
      </CardsList>
    </Section>
  );
};

export default Stats;
