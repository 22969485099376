import React from 'react';
import styled from 'styled-components';
import { media } from '@tymate/margaret';
import { Container, Title } from 'ui';
import { Link } from 'gatsby';
import { MdChevronRight } from 'react-icons/md';
import { Stack } from '@tymate/margaret';

const MoreLinkBase = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.5)} 0;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  text-decoration: none;
  position: relative;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.secondary};
  }

  &:before {
    position: absolute;
    left: -10000px;
    right: 0;
    content: '';
    border-bottom: 1px solid currentColor;
    bottom: 0;
  }
`;

const SectionMoreLink = ({ to, children, ...props }) => (
  <Stack size="full" alignX="center" marginTop={2}>
    <MoreLinkBase to={to} {...props}>
      <Stack direction="row" alignY="center">
        <span>
          <MdChevronRight size={24} />
        </span>
        <span>{children}</span>
      </Stack>
    </MoreLinkBase>
  </Stack>
);

export const SectionBase = styled.section`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-bottom: ${({ theme }) => theme.spacing(3)};
  `}

  ${media.desktop`
    padding-top: ${({ theme }) => theme.spacing(4)};
    padding-bottom: ${({ theme }) => theme.spacing(4)};
  `}

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
      background-color: ${theme.primary};
      color: #ffffff;

      ${Title}:before {
        background-color: #fff;
      }
    `}

`;

const Section = ({ children, variant, footerElement, ...props }) => (
  <SectionBase variant={variant} {...props}>
    <Container>{children}</Container>
    {footerElement}
  </SectionBase>
);

Section.MoreLink = SectionMoreLink;

export default Section;
